<template>
  <div class="terms-page">
    <h2>Scope</h2>
    <p>This document provides you with information about how Feel Good Hub is handling, or is intending to handle, your personal information collected via the Feel Good Hub app (‘the app’).</p><p>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyPage',
}
</script>
